<template>
  <div v-if="$viewport.desktop"
       class="interesting-facts__slider-holder">
    <Row class="interesting-facts__slider">
      <InterestingFact :key="fact.id"
                       :params="{transition: 'scale', fact}"
                       v-for="fact in $facts.data"/>
    </Row>
    <InterestingFactsButton :params="{suffix: 'next'}"
                            v-on:click.native="next"/>
    <InterestingFactsButton :params="{suffix: 'preview'}"
                            v-on:click.native="preview"/>
  </div>
</template>

<script>
import InterestingFactsButton from './InterestingFactsButton.vue';

export default {
  name: 'InterestingFactsDesktopSlider',
  components: { InterestingFactsButton },
  data() {
    return {
      temp: '',
    };
  },
  props: {
    cityId: {
      default: null,
    },
  },
  created() {
    if (this.cityId === null) {
      this.$store.dispatch('GET_FACTS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
      });
    } else {
      this.$getFactsByCity(this.cityId);
    }
  },
  watch: {
    $city() {
      this.$getFactsByCity(this.cityId);
    },
  },
  methods: {
    getFirst() {
      this.temp = this.$facts.data.find((item, index) => (index === 0 ? item : ''));
    },
    getLast() {
      this.temp = this.$facts.data.find((item, index, array) => (index === array.length - 1 ? item : ''));
    },
    next() {
      this.getFirst();
      this.$store.commit('REMOVE_FIRST_FACT');
      this.$nextTick(() => {
        this.$store.commit('ADD_LAST_FACT', this.temp);
      });
    },
    preview() {
      this.getLast();
      this.$store.commit('REMOVE_LAST_FACT');
      this.$nextTick(() => {
        this.$store.commit('ADD_FIRST_FACT', this.temp);
      });
    },
  },
};
</script>
