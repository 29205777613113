<template>
  <Column v-if="$viewport.mobile"
          class="interesting-facts__slider-holder-mobile"
          v-touch:swipe.left="nextSlide"
          v-touch:swipe.right="previewSlide">
    <div class="interesting-facts__slider-mobile">
      <InterestingFact
        :params="{transition: 'fade', fact: $facts.data[current]}"
      />
    </div>
    <Row class="interesting-facts__slider-mobile-pagination">
      <div class="interesting-facts__slider-mobile-pagination-item"
           :class="current === index ?
           'interesting-facts__slider-mobile-pagination-item-active' : ''"
           v-on:click="changeCurrent(index)"
           v-for="(fact, index) in $facts.data"
           :key="fact.id">
      </div>
    </Row>
  </Column>
</template>

<script>
export default {
  name: 'InterestingFactsMobileSlider',
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    nextSlide() {
      if (this.current < this.$facts.data.length - 1) this.current += 1;
    },
    previewSlide() {
      if (this.current > 0) this.current -= 1;
    },
    changeCurrent(current) {
      this.current = current;
    },
  },
};
</script>
