<template>
  <div class="interesting-facts">
    <div class="interesting-facts__wrapper">
      <Row justify="between"
           :wrap="$viewport.mobile">
        <Column class="interesting-facts__add">
          <h2 class="interesting-facts__add-title">Интересные факты</h2>
          <Button v-if="$viewport.desktop"
                  color="green"
                  v-on:click.native="$openPopupAddFact">добавить факт
          </Button>
        </Column>
        <InterestingFactsDesktopSlider/>
        <InterestingFactsMobileSlider/>
      </Row>
      <Row class="interesting-facts__button-mobile">
        <Button v-on:click.native="$openPopupAddFact"
                v-if="$viewport.mobile"
                color="green"
                full>добавить факт
        </Button>
      </Row>
    </div>
  </div>
</template>

<script>
import InterestingFactsMobileSlider from './InterestingFactsMobileSlider.vue';
import InterestingFactsDesktopSlider from './InterestingFactsDesktopSlider.vue';

export default {
  name: 'InterestingFacts',
  components: {
    InterestingFactsDesktopSlider,
    InterestingFactsMobileSlider,
  },
};
</script>
