<template>
  <div class="interesting-facts__navigation-button"
       :class="`interesting-facts__${params.suffix}`">
    <Icon class="interesting-facts__navigation-button-svg"
          viewport="0 0 25 18"
          :xlink="`arrow-slider-${params.suffix}`"/>
  </div>
</template>

<script>
export default {
  name: 'InterestingFactsButton',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>
